<template>
    <!-- 船舶监理进度设置 -->
    <div class="mod-config">
        <div>
            <el-card>
                <div class="title">船舶进度控制</div>
                <div class="line"></div>
                <el-row style="margin-top: -10px;margin-left: -20px;line-height:30px;width: 103%;">
                    <el-row style="margin-top: 10px;">
                        <el-col :span="2">
                            <div class="search_title">标的物</div>
                        </el-col>
                        <el-col :span="22" style="display: flex;flex-wrap: wrap;">
                            <span v-for="(subject, index) in subjectList" style="margin-right: 20px;">
                                <span class="search_bn_border" v-if="subjectNo === subject.displayValue"
                                    @click="setSubject(subject)">
                                    {{ subject.displayName }}
                                </span>
                                <span class="search_bn" v-else-if="subjectNo !== subject.displayValue"
                                    @click="setSubject(subject)">
                                    {{ subject.displayName }}
                                </span>
                            </span>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top: 15px;">
                        <el-col :span="2">
                            <div class="search_title">专业</div>
                        </el-col>
                        <el-col :span="22" style="display: flex;flex-wrap: wrap;">
                            <span v-for="(maj, index) in majorList" style="margin-right: 20px;">
                                <span class="search_bn_border" v-if="majorNo === maj.displayValue" @click="setMajor(maj)">
                                    {{ maj.displayName }}
                                </span>
                                <span class="search_bn" v-else-if="majorNo !== maj.displayValue" @click="setMajor(maj)">
                                    {{ maj.displayName }}
                                </span>
                            </span>
                        </el-col>
                    </el-row>
                    <div class="line" style="margin-left:0px;width: 100%"></div>
                    <el-row style="min-height: 20px;margin-top: 12px;">
                        <el-col :span="22"  style="display: flex;flex-wrap: wrap;margin-left: 20px;margin-top:-5px;margin-bottom:5px">
                            <span v-for="(name, index) in progressNameList" style="margin-right: 20px;">
                                <span class="search_bn_border" v-if="progressNo === name.displayValue"
                                    @click="setSubProgressName(name)">
                                    {{ name.displayName }}
                                </span>
                                <span class="search_bn" v-else-if="progressNo !== name.displayValue"
                                    @click="setSubProgressName(name)">
                                    {{ name.displayName }}
                                </span>
                            </span>
                        </el-col>
                    </el-row>
                </el-row>
                <div class="line" style="margin-top: -4px;"></div>
                <el-row style="background-color: rgb(243, 247, 247);margin-left:-20px;margin-top: -10px;height: 45px;width: 103%;margin-bottom:13px">
                    <el-col :span="pageSpan" v-if="showAdd" style="margin-left:15px;margin-top:2px">
                        <el-button class="btn" type="primary" size="small" v-preventReClick @click="addHandle()"
                            style="margin-top:5px" :disabled="updateFlg || addFlg || canAddFlg">添加</el-button>
                        <el-button class="btn" type="primary" size="small" v-preventReClick
                            :disabled="disabledBatchDelete || updateFlg || addFlg" @click="deleteBatch()">删除</el-button>
                        <el-button class="btn" type="primary" size="small" v-preventReClick
                            :disabled="updateFlg || addFlg" @click="uploadHandle()">导入</el-button>
                        <el-button class="btn" type="primary" size="small" v-preventReClick
                            :disabled="updateFlg || addFlg" @click="downloadHandle()">导出</el-button>
                        <el-button class="btn" type="primary" size="small" v-preventReClick
                            :disabled="updateFlg || addFlg" @click="downloadAllDataHandle()">所有数据导出</el-button>
                    </el-col>
                </el-row>
                <div class="line"></div>
            </el-card>
            <div>
                <el-card style="margin-top: -45px; height: 460px;margin-bottom:-20px"
                    body-style="height:420px; padding: 0;">
                    <el-row>
                        <el-col :span="24">
                            <div class="box">
                                <el-table max-height="400" :row-class-name="tableRowClassName"
                                    :row-style="selectRowBackground" ref="dataListTable" class="dataListTable" border
                                    v-if="tableHeader != undefined && tableHeader.length > 0" :data="dataList"
                                    header-align="center" header-cell-class-name="dataListTHeader"
                                    @selection-change="handleSelectionChange" :cell-style="{ padding: '0px' }"
                                    style="margin-bottom: 20px;">
                                    <el-table-column type="selection" width="50" align="center"></el-table-column>
                                    <el-table-column label="NO." width="60" align="center">
                                        <template slot-scope="scope">{{ (pageIndex2 - 1) * pageSize + scope.$index + 1
                                        }}</template>
                                    </el-table-column>
                                    <el-table-column v-for="(v, idx) in tableHeader" v-if="idx == 0" :key="idx"
                                        :label="v.subProgressName" :prop="v.colName" align="center" min-width="150px">
                                        <template slot-scope="scope">
                                            <span v-if="!getDisabled(scope.row.recNo)">{{ scope.row.itemName }}</span>
                                            <el-input v-if="getDisabled(scope.row.recNo)" class="sp-input"
                                                v-model="scope.row.itemName"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column v-for="(v, idx) in tableHeader"
                                        v-if="tableHeader.length > 1 && idx >= 1" :key="idx" :label="v.subProgressName"
                                        :prop="v.colName" align="center" min-width="130px">
                                        <template slot-scope="scope">
                                            <span v-if="!getDisabled(scope.row.recNo)">{{
                                                scope.row.contentList[idx].colValue }}</span>
                                            <el-input v-if="getDisabled(scope.row.recNo)" class="sp-input"
                                                v-model="scope.row.contentList[idx].colValue"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="备注" width="200" align="center">
                                        <template slot-scope="scope">
                                            <span v-if="!getDisabled(scope.row.recNo)">{{ scope.row.remark }}</span>
                                            <el-input v-if="getDisabled(scope.row.recNo)" class="sp-input"
                                                v-model="scope.row.remark"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column align="center" width="100" label="">
                                        <template slot-scope="scope">
                                            <el-button type="text" size="small" v-if="!updateFlg && !addFlg"
                                                @click="updateHandle(scope.row.recNo)">修改</el-button>
                                            <!--添加时按钮-->
                                            <el-button v-preventReClick type="text" size="small" @click="saveRecordHandle()"
                                                v-if="scope.row.recNo == '' && addFlg">保存</el-button>
                                            <el-button v-preventReClick type="text" size="small" @click="cancelSaveHandle()"
                                                v-if="scope.row.recNo == '' && addFlg">取消</el-button>
                                            <!--修改时按钮-->
                                            <el-button v-preventReClick type="text" size="small"
                                                @click="updateRecordHandle(scope.row)"
                                                v-if="getDisabled(scope.row.recNo) && updateFlg">保存</el-button>
                                            <el-button v-preventReClick type="text" size="small"
                                                @click="cancelUpdateHandle()"
                                                v-if="getDisabled(scope.row.recNo) && updateFlg">取消</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                            <div style="margin-right:10px;">
                                <el-pagination class="my-pagination" @size-change="sizeChangeHandle"
                                    @current-change="currentChangeHandle" :current-page="pageIndex"
                                    :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
                                    layout="total, sizes, prev, pager, next, jumper" :disabled="updateFlg || addFlg">
                                </el-pagination>
                            </div>
                        </el-col>
                    </el-row>
                </el-card>
            </div>
        </div>
        <excel-upload v-if="excelUploadVisible" ref="excelUpload" @refreshDataList="getDataList"></excel-upload>
    </div>
</template>
<script>
import $common from "@/utils/common.js"
import ExcelUpload from './progress-excel-upload'
export default {
    data() {
        return {
            partNo: '',
            showSearch: true,
            showAdd: true,
            showDelete: true,
            showEdit: true,
            disabledBatchDelete: true,
            pageSpan: 12,
            addOrUpdateVisible: false,
            excelUploadVisible: false,
            updateFlg: false,
            addFlg: false,
            canAddFlg: false,
            updateRecNo: '',
            form: {},
            //当前页码
            pageIndex: 1,
            //当前记录数
            pageSize: 20,
            // 显示序号
            pageIndex2: 1,
            //总页数
            totalPage: 0,
            workNo: $common.getItem("workNo"),
            dataList: [],
            subjectList: [],
            majorList: [],
            majorNo: '',
            subjectNo: '',
            progressNo: '',
            progressNameList: '',
            tableSelVal: [],
            tableHeader: ''
        };
    },
    components: {
        ExcelUpload
    },
    mounted() {
        this.pageIndex = 1;
        this.pageIndex2 = 1;
        this.totalPage = 0;
        // this.getDataList();
        this.getSubjectDropDown();
        this.getMajorList();
        this.getSubProgressNameList();
    },
    methods: {
        getDisabled(val) {
            if (val == '' || val == undefined) {
                return true;
            } else {
                if (this.updateRecNo === val && this.updateFlg) {
                    return true;
                }
                return false;
            }
        },
        // 获取数据列表
        getDataList() {
            this.$http({
                url: this.$store.state.httpUrl + "/business/projectsubprogressrecord/list",
                method: 'get',
                params: this.$http.adornParams({
                    workNo: $common.getItem("workNo"),
                    majorNo: this.majorNo,
                    subjectNo: this.subjectNo,
                    progressNo: this.progressNo,
                    page: this.pageIndex,
                    limit: this.pageSize,
                })
            }).then(({
                data
            }) => {
                if (data && data.resultCode === 200) {
                    this.dataList = data.body.dtoList;
                    this.totalPage = data.body.totalCount;
                    this.pageIndex2 = this.pageIndex
                }
            })
        },
        getSubjectDropDown() {
            this.$http({
                url: this.$store.state.httpUrl + "/common/getSubjectDropDown/" + $common.getItem("workNo"),
                method: "get",
                params: {},
            }).then(({ data }) => {
                if (data && data.resultCode === 200) {
                    this.subjectList = data.body;
                    if (this.subjectList != undefined && this.subjectList.length > 0) {
                        this.subjectNo = this.subjectList[0].displayValue
                    }
                }
            });
        },
        getMajorList() {
            this.$http({
                url: this.$store.state.httpUrl + "/common/getMajorByWorkNo/" + $common.getItem("workNo"),
                method: "get",
                params: {},
            }).then(({ data }) => {
                if (data && data.resultCode === 200) {
                    this.majorList = data.body;
                    if (this.majorList != undefined && this.majorList.length > 0) {
                        this.majorNo = this.majorList[0].displayValue
                        this.getSubProgressNameList();
                    }
                }
            });
        },
        getSubProgressNameList() {
            if (this.majorNo !== '') {
                this.$http({
                    url: this.$store.state.httpUrl + "/business/projectprogress/getSubProgressNameList",
                    method: "get",
                    params: {
                        workNo: $common.getItem("workNo"),
                        majorNo: this.majorNo,
                    }
                }).then(({ data }) => {
                    if (data && data.resultCode === 200) {
                        this.progressNameList = data.body;
                        if (this.progressNameList != undefined && this.progressNameList.length > 0) {
                            this.progressNo = this.progressNameList[0].displayValue
                            this.getProgressRecordHeader();
                        } else {
                            this.canAddFlg = true;
                            this.tableHeader = []
                            this.dataList = data.body.dtoList;
                            this.totalPage = data.body.totalCount;
                            this.pageIndex2 = this.pageIndex
                        }
                    }
                });
            }
        },
        getProgressRecordHeader() {
            if (this.majorNo !== '') {
                this.$http({
                    url: this.$store.state.httpUrl + "/business/projectprogress/getProgressRecordHeader",
                    method: "get",
                    params: {
                        workNo: $common.getItem("workNo"),
                        majorNo: this.majorNo,
                        subjectNo: this.subjectNo,
                        progressNo: this.progressNo
                    }
                }).then(({ data }) => {
                    if (data && data.resultCode === 200) {
                        this.tableHeader = data.body;
                        this.getDataList()
                    }
                });
            }
        },
        deleteBatch() {
            this.$confirm(`确定对选中的数据进行删除操作？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let records = this.tableSelVal.map((item) => item.recNo);
                this.$http({
                    url: this.$store.state.httpUrl + "/business/projectsubprogressrecord/deleteBatch",
                    method: 'post',
                    data: this.$http.adornData({
                        records: records
                    })
                }).then(({ data }) => {
                    if (data && data.resultCode === 200) {
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                            duration: 1500,
                            onClose: () => {
                                this.getDataList()
                            }
                        })
                    }
                });
            });
        },
        uploadHandle() {
            if (!this.subjectNo) {
				this.$message.error("标的物未选择")
				return;
			}
			if (!this.majorNo) {
				this.$message.error("专业未选择")
				return;
			}
			if (!this.progressNo) {
				this.$message.error("进度项目未选择")
				return;
			}
            this.excelUploadVisible = true;
			this.$nextTick(() => {
				this.$refs.excelUpload.init(this.subjectNo, this.majorNo, this.progressNo)
			})
        },
        downloadHandle() {
            var subjectName = '';
            for (let index = 0; index < this.subjectList.length; index++) {
                if(this.subjectNo == this.subjectList[index].displayValue) {
                    subjectName = this.subjectList[index].displayName;
                    break;
                }
                
            }
            var majorName = '';
            for (let index = 0; index < this.majorList.length; index++) {
                if(this.majorNo == this.majorList[index].displayValue) {
                    majorName = this.majorList[index].displayName;
                    break;
                }
            }
            var projectName = '';
            for (let index = 0; index < this.progressNameList.length; index++) {
                if(this.progressNo == this.progressNameList[index].displayValue) {
                    projectName = this.progressNameList[index].displayName;
                    break;
                }
            }
            var fileName = subjectName + "-" + majorName + "-" + projectName + "进度表.xlsx"
            this.$http({
                url: this.$store.state.httpUrl + "/business/projectsubprogressrecord/downloadFile",
                method: "GET",
                params: this.$http.adornParams({
					subjectNo: this.subjectNo,
					major: this.major,
					progressNo: this.progressNo,
				})
            }).then(({ data }) => {
                if (data && data.resultCode === 200) {
                    this.$message({
                        message: '操作成功',
                        type: 'success',
                        duration: 1500,
                        onClose: () => {
                            this.global.downLoadFile(data.body, fileName);
                        }
                    })
                }
            });
		},
        downloadAllDataHandle() {
            var subjectName = '';
            for (let index = 0; index < this.subjectList.length; index++) {
                if(this.subjectNo == this.subjectList[index].displayValue) {
                    subjectName = this.subjectList[index].displayName;
                    break;
                }
            }
            var fileName = subjectName + "进度控制表格.xlsx"
            const rLoading = this.openLoading();
			this.$http({
				url: this.$store.state.httpUrl + "/business/projectsubprogressrecord/downloadAllData",
				method: 'get',
				params: this.$http.adornParams({
					workNo: $common.getItem("workNo"),
					subjectNo: this.subjectNo,
				})
			}).then(({ data }) => {
				rLoading.close();
				if (data && data.resultCode === 200) {
					if (this.$store.state.showTestFlag) {
						window.open(data.body)
					} else {
						this.global.downLoadFile(data.body, fileName);
					}
				} else {
				}
			})
        },
        addOrUpdateHandle(progressNo, majorNo) {
            this.addOrUpdateVisible = true
            this.$nextTick(() => {
                this.$refs.addOrUpdate.init(progressNo, majorNo)
            })
        },
        // 每页数
        sizeChangeHandle(val) {
            this.pageSize = val;
            this.pageIndex = 1;
            this.getDataList();
        },
        // 当前页
        currentChangeHandle(val) {
            this.pageIndex = val;
            this.getDataList();
        },
        handleSelectionChange(selection) {
            this.tableSelVal = selection;
            if (selection.length > 0) {
                this.disabledBatchDelete = false;
            } else {
                this.disabledBatchDelete = true;
            }
        },
        setMajor(major) {
            if (!this.addFlg && !this.updateFlg) {
                this.majorNo = major.displayValue
                this.getSubProgressNameList();
            }
            // this.canAddFlg = false;
        },
        setSubject(subject) {
            if (!this.addFlg && !this.updateFlg) {
                this.subjectNo = subject.displayValue
                this.getDataList()
            }
        },
        setSubProgressName(name) {
            if (!this.addFlg && !this.updateFlg) {
                this.progressNo = name.displayValue;
                this.getProgressRecordHeader();
            }
        },
        addHandle() {
            if (!this.addFlg) {
                this.$http({
                    url: this.$store.state.httpUrl + "/business/projectsubprogressrecord/getRecSort",
                    method: "post",
                    data: this.$http.adornData({
                        // workNo: $common.getItem("workNo"),
                        // majorNo: this.majorNo,
                        subjectNo: this.subjectNo,
                        progressNo: this.progressNo
                    })
                }).then(({ data }) => {
                    if (data && data.resultCode === 200) {
                        this.addFlg = true;
                        let contentList = []
                        if (this.tableHeader != undefined && this.tableHeader.length > 0) {
                            for (var i = 0; i < this.tableHeader.length; i++) {
                                let content = {
                                    colName: this.tableHeader[i].colName,
                                    colValue: ''
                                }
                                contentList.push(content)
                            }
                        }
                        let record = {
                            contentList: contentList,
                            remark: '',
                            recSort: data.body,
                            progressNo: this.progressNo,
                            subjectNo: this.subjectNo,
                            recNo: '',
                        }
                        let dataList = [];
                        dataList.push(record);
                        if (this.dataList != undefined && this.dataList.length > 0) {
                            for (var i = 0; i < this.dataList.length; i++) {
                                dataList.push(this.dataList[i])
                            }
                        }
                        this.dataList = dataList
                    }
                });
            }
        },
        updateHandle(val) {
            this.updateRecNo = val
            this.updateFlg = true;
        },
        saveRecordHandle() {
            let _url = "/business/projectsubprogressrecord/save";
            this.$http({
                url: this.$store.state.httpUrl + _url,
                method: "post",
                data: this.$http.adornData(this.dataList[0])
            }).then(({
                data
            }) => {
                if (data && data.resultCode === 200) {
                    this.$message({
                        message: "操作成功",
                        type: "success",
                        duration: 1500,
                    });
                    this.getDataList()
                    this.addFlg = false
                }
            });
        },
        cancelSaveHandle() {
            this.addFlg = false;
            this.getDataList();
        },
        updateRecordHandle(val) {
            let _url = "/business/projectsubprogressrecord/update";
            this.$http({
                url: this.$store.state.httpUrl + _url,
                method: "post",
                data: this.$http.adornData(val)
            }).then(({
                data
            }) => {
                if (data && data.resultCode === 200) {
                    this.$message({
                        message: "操作成功",
                        type: "success",
                        duration: 1500,
                    });
                    this.getDataList()
                    this.updateFlg = false
                    this.updateRecNo = '';
                }
            });
        },
        cancelUpdateHandle() {
            this.updateFlg = false;
            this.updateRecNo = ''
            this.getDataList()
        },
        tableRowClassName({ row, rowIndex }) {
            return $common.tableRowClassName({ row, rowIndex });
        },
        selectRowBackground({ row }) {
            const checkIdList = this.tableSelVal.map((item) => item.recNo);
            if (checkIdList.includes(row.recNo)) {
                return {
                    backgroundColor: "#fae8cb",
                };
            }
        },
    }
};
</script>
<style scoped>
.search_title {
    font-size: 13px;
    margin-right: 20px;
    margin-left: 20px;
}

/* 横线 */
.sp-input /deep/ .el-input__inner {
    width: 100% !important;
}
</style>
